import { Static, Type } from '@sinclair/typebox'
import { MemberInviter } from '../../member'

export const InviteByEmailRequest = Type.Object({
    emails: Type.Array(Type.String()),
    projectId: Type.String(),
    platformId: Type.Union([Type.String(), Type.Null()]),
    invitedBy: MemberInviter,
    invitedAt: Type.String(),
    teamId: Type.String(),
})

export type InviteByEmailRequest = Static<typeof InviteByEmailRequest>

export const InviteVerificationRequest = Type.Object({
    email: Type.String(),
    token: Type.String(),
})

export type InviteVerificationRequest = Static<typeof InviteVerificationRequest>
