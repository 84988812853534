import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SubscriptionCheckRequest } from '@upbrains/shared';
import { Observable } from 'rxjs';
import { UpbrainsUserSelectors } from '../../store';
import { Router } from '@angular/router';

@Component({
  selector: 'ap-subscription-banner',
  templateUrl: './subscription-banner.html',
})
export class SubscriptionBanner {
  subscription$: Observable<SubscriptionCheckRequest | undefined | null>;
  planName: string | undefined | null = '';
  planDuration: number | undefined | null = 0;
  daysLeft: number = 0;

  constructor(private store: Store, public router: Router) {
    this.subscription$ = this.store.select(
      UpbrainsUserSelectors.selectUpbrainsUserSubscriptionCheck
    );
    this.subscription$.subscribe((subscriptionCheck) => {
      const workflowAiSubscription = subscriptionCheck?.user_subscriptions[0];

      this.planName = workflowAiSubscription?.plan_name;
      this.planDuration =
        workflowAiSubscription?.subscription_renewal_date_timestamp;
      this.daysLeft = this.getDaysLeft(this.planDuration);
    });
  }

  getDaysLeft(timestamp: number | null | undefined): number {
    if (timestamp) {
      const targetDate: Date = new Date(timestamp * 1000);
      const currentDate: Date = new Date();
      const timeDifference: number =
        targetDate.getTime() - currentDate.getTime();
      let daysLeft: number = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      if (daysLeft > 0) {
        return daysLeft;
      }
      return 0;
    }
    return 0;
  }

  handleSubscription() {
    this.router.navigate(['/subscriptions']);
  }

  handleUpgrade(event: any) {
    event.stopPropagation();
    this.router.navigate(['/subscriptions/plans'], {
      queryParams: { title: this.planName },
    });
  }
}
