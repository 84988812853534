import { Routes } from '@angular/router';
import { SubscriptionPlansComponent } from './pages/subscription-plans/subscription-plans.component';

export const SubscriptionLayoutRouting: Routes = [
  {
    path: 'subscriptions/plans',
    component: SubscriptionPlansComponent,
    runGuardsAndResolvers: 'always',
  },
];
