import {
  AppConnectionType,
  AuthenticationResponse,
  DefaultUpbrainsAgentsConnection,
  AgentConnection,
  UpbrainsResetPassword,
  UpbrainsServicesAgentConnections,
  UpbrainsUser,
  UpbrainsUserConnections,
  UpsertSecretTextRequest,
} from '@upbrains/shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Base64 from 'js-base64';
import { environment } from '../../environments/environment';
import { AppConnectionsService } from '../app-connections.service';
import { AuthenticationService } from '../authentication.service';
import { FlagService } from '../flag.service';
import { RedirectService } from './../redirect.service';
import axios, { AxiosResponse } from 'axios';
import { Store } from '@ngrx/store';
import { saveUpbrainsUser } from '../../store';
import { PermissionService } from '../permission.service';

interface Params {
  [key: string]: string;
}
@Injectable({
  providedIn: 'root',
})
export class upbrainsAuthenticationService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private mainAuth: AuthenticationService,
    private redirectService: RedirectService,
    private flagsService: FlagService,
    private connectionService: AppConnectionsService,
    private permissionService: PermissionService,
    private store: Store
  ) {}

  private decodeBase64(encodedString: string): string {
    return Base64.decode(encodedString);
  }

  // private redirectUpbrainsUserToWorkflowId(workflowId: string | 'new') {
  //   if (workflowId !== 'new') {
  //     this.redirectService.setRedirectRouteToCurrentRoute();
  //     this.router.navigate(['/flows/', workflowId]);
  //   } else {
  //     localStorage.setItem('newFlow', 'true');
  //     this.flowService
  //       .create({
  //         displayName: $localize`Untitled`,
  //         projectId: this.mainAuth.getProjectId(),
  //       })
  //       .subscribe({
  //         next: (flow) => {
  //           localStorage.setItem('newFlow', 'true');
  //           this.router.navigate(['/flows/', flow.id]);
  //         },
  //         error: (error) => {
  //           // Handle error if necessary
  //           console.error('Error creating flow:', error);
  //         },
  //       });
  //   }
  // }

  private async resetPassword(
    params: UpbrainsResetPassword
  ): Promise<AuthenticationResponse> {
    try {
      const response: AxiosResponse<AuthenticationResponse> =
        await axios.post<AuthenticationResponse>(
          environment.apiUrl + '/authentication/upbrains-reset-password',
          params,
          {
            headers: { 'Content-Type': 'application/json' }, // Set headers if needed
          }
        );
      return response.data;
    } catch (error) {
      console.error('Error during reset password', error);
      throw error;
    }
  }

  async authenticateUpBrainsUser(
    encodedUserEmail: string,
    encodedToken: string,
    params: Params
  ): Promise<boolean> {
    try {
      const userEmail = this.decodeBase64(encodedUserEmail);
      const userToken = this.decodeBase64(encodedToken);
      const encodedWorkflowId = params['w'];
      const workflowId = encodedWorkflowId
        ? this.decodeBase64(encodedWorkflowId)
        : undefined;

      localStorage.removeItem('upbrainsUser');
      localStorage.removeItem(environment.jwtTokenName);
      localStorage.removeItem(environment.userPropertyNameInLocalStorage);
      localStorage.removeItem('isAdmin');
      // this.currentUserSubject.next(undefined);
      this.flagsService.reinitialiseFlags();

      const upBrainsUser = (await this.http
        .post(environment.apiUrl + '/authentication/upbrains-authentication', {
          email: userEmail,
          token: userToken,
        })
        .toPromise()) as UpbrainsUser;

      if (upBrainsUser) {
        const userConnections: UpbrainsUserConnections = {
          has_one_drive_cred: upBrainsUser.has_onedrive_cred
            ? '@upbrains/agent-microsoft-onedrive'
            : false,
          has_microsoft_cred: upBrainsUser.has_microsoft_cred
            ? '@upbrains/agent-microsoft-outlook'
            : false,
          has_conversation_skills: '@upbrains/agent-conversation-skills',
          has_document_skills: '@upbrains/agent-document-skills',
          has_response_assist: '@upbrains/agent-response-assist',
          has_save_in_database: '@upbrains/agent-save-in-upbrains',
          has_smart_classifier: '@upbrains/agent-smart-classifier',
        };

        if (upBrainsUser?.first_name) {
          localStorage.setItem('UB-user', JSON.stringify(upBrainsUser));
          const isLoggedIn = await this.signInWithUpbrains(
            userEmail,
            upBrainsUser.password,
            upBrainsUser.team_id
          );

          if (!isLoggedIn?.token) {
            const registered = await this.signUpWithUpbrains(
              userEmail,
              upBrainsUser?.first_name,
              userToken,
              upBrainsUser.password
            );

            if (registered?.token) {
              this.storeUpbrainsUser(upBrainsUser, registered.token);

              const projectId = this.mainAuth.getProjectId();
              localStorage.setItem(
                'isAdmin',
                JSON.stringify(upBrainsUser?.is_admin)
              );
              // Check each connection type and create if the user has it

              this.createUpbrainsUserConnections(
                userConnections,
                projectId,
                upBrainsUser?.api_key
              );

              if (workflowId) {
                // this.redirectUpbrainsUserToWorkflowId(workflowId);
              } else {
                this.redirectService.setRedirectRouteToCurrentRoute();
                this.router.navigate(['/agents']);
              }
              return true;
            } else {
              // TODO: have to use ignite password for resetPasswordWithUpbrains
              const loggedInByResetPassword =
                await this.resetPasswordWithUpbrains(
                  userEmail,
                  upBrainsUser.password,
                  upBrainsUser.team_id
                );
              if (loggedInByResetPassword?.token) {
                this.storeUpbrainsUser(
                  upBrainsUser,
                  loggedInByResetPassword.token
                );

                localStorage.setItem(
                  'isAdmin',
                  JSON.stringify(upBrainsUser?.is_admin)
                );
                const projectId = this.mainAuth.getProjectId();
                const connectionsList = await this.connectionService
                  .list({
                    projectId,
                  })
                  .toPromise();
                const userDefaultConnections = [
                  '@upbrains/agent-conversation-skills',
                  '@upbrains/agent-document-skills',
                  '@upbrains/agent-response-assist',
                  '@upbrains/agent-save-in-upbrains',
                  '@upbrains/agent-smart-classifier',
                ];

                if (upBrainsUser?.has_microsoft_cred) {
                  userDefaultConnections.push(
                    '@upbrains/agent-microsoft-outlook'
                  );
                }

                if (upBrainsUser?.has_zendesk_cred) {
                  userDefaultConnections.push('@upbrains/agent-zendesk');
                }

                if (upBrainsUser?.has_google_cred) {
                  userDefaultConnections.push('@upbrains/agent-google-drive');
                }

                if (upBrainsUser?.has_onedrive_cred) {
                  userDefaultConnections.push(
                    '@upbrains/agent-microsoft-onedrive'
                  );
                }

                const missingConnections = userDefaultConnections.filter(
                  (connection) =>
                    !connectionsList?.data.some(
                      (c) => c.agentName === connection
                    )
                );
                const _userConnections: UpbrainsUserConnections = {
                  has_one_drive_cred: missingConnections.includes(
                    '@upbrains/agent-microsoft-onedrive'
                  )
                    ? '@upbrains/agent-microsoft-onedrive'
                    : false,
                  has_microsoft_cred: missingConnections.includes(
                    '@upbrains/agent-microsoft-outlook'
                  )
                    ? '@upbrains/agent-microsoft-outlook'
                    : false,
                  has_conversation_skills: missingConnections.includes(
                    '@upbrains/agent-conversation-skills'
                  )
                    ? '@upbrains/agent-conversation-skills'
                    : false,
                  has_document_skills: missingConnections.includes(
                    '@upbrains/agent-document-skills'
                  )
                    ? '@upbrains/agent-document-skills'
                    : false,
                  has_response_assist: missingConnections.includes(
                    '@upbrains/agent-response-assist'
                  )
                    ? '@upbrains/agent-response-assist'
                    : false,
                  has_save_in_database: missingConnections.includes(
                    '@upbrains/agent-save-in-upbrains'
                  )
                    ? '@upbrains/agent-save-in-upbrains'
                    : false,
                  has_smart_classifier: missingConnections.includes(
                    '@upbrains/agent-smart-classifier'
                  )
                    ? '@upbrains/agent-smart-classifier'
                    : false,
                };

                this.createUpbrainsUserConnections(
                  _userConnections,
                  projectId,
                  upBrainsUser.api_key
                );

                if (workflowId) {
                  // this.redirectUpbrainsUserToWorkflowId(workflowId);
                } else {
                  this.redirectService.setRedirectRouteToCurrentRoute();
                  this.router.navigate(['/flows']);
                }
                return true;
              } else {
                return false;
              }
            }
          } else {
            this.storeUpbrainsUser(upBrainsUser, isLoggedIn.token);

            localStorage.setItem(
              'isAdmin',
              JSON.stringify(upBrainsUser?.is_admin)
            );
            const projectId = this.mainAuth.getProjectId();
            const connectionsList = await this.connectionService
              .list({
                projectId,
              })
              .toPromise();
            const userDefaultConnections = [
              '@upbrains/agent-conversation-skills',
              '@upbrains/agent-document-skills',
              '@upbrains/agent-response-assist',
              '@upbrains/agent-save-in-upbrains',
              '@upbrains/agent-smart-classifier',
            ];

            if (upBrainsUser?.has_microsoft_cred) {
              userDefaultConnections.push('@upbrains/agent-microsoft-outlook');
            }

            if (upBrainsUser?.has_zendesk_cred) {
              userDefaultConnections.push('@upbrains/agent-zendesk');
            }

            if (upBrainsUser?.has_google_cred) {
              userDefaultConnections.push('@upbrains/agent-google-drive');
            }

            if (upBrainsUser?.has_onedrive_cred) {
              userDefaultConnections.push('@upbrains/agent-microsoft-onedrive');
            }

            const missingConnections = userDefaultConnections.filter(
              (connection) =>
                !connectionsList?.data.some((c) => c.agentName === connection)
            );
            const _userConnections: UpbrainsUserConnections = {
              has_one_drive_cred: missingConnections.includes(
                '@upbrains/agent-microsoft-onedrive'
              )
                ? '@upbrains/agent-microsoft-onedrive'
                : false,
              has_microsoft_cred: missingConnections.includes(
                '@upbrains/agent-microsoft-outlook'
              )
                ? '@upbrains/agent-microsoft-outlook'
                : false,
              has_conversation_skills: missingConnections.includes(
                '@upbrains/agent-conversation-skills'
              )
                ? '@upbrains/agent-conversation-skills'
                : false,
              has_document_skills: missingConnections.includes(
                '@upbrains/agent-document-skills'
              )
                ? '@upbrains/agent-document-skills'
                : false,
              has_response_assist: missingConnections.includes(
                '@upbrains/agent-response-assist'
              )
                ? '@upbrains/agent-response-assist'
                : false,
              has_save_in_database: missingConnections.includes(
                '@upbrains/agent-save-in-upbrains'
              )
                ? '@upbrains/agent-save-in-upbrains'
                : false,
              has_smart_classifier: missingConnections.includes(
                '@upbrains/agent-smart-classifier'
              )
                ? '@upbrains/agent-smart-classifier'
                : false,
            };

            this.createUpbrainsUserConnections(
              _userConnections,
              projectId,
              upBrainsUser.api_key
            );

            if (workflowId) {
              // this.redirectUpbrainsUserToWorkflowId(workflowId);
            } else {
              this.redirectService.setRedirectRouteToCurrentRoute();
              this.router.navigate(['/flows']);
            }
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error('error', error);
      return false;
    }
  }

  private createUpbrainsUserConnections = (
    userConnections: UpbrainsUserConnections,
    projectId: string,
    password: string
  ) => {
    const aiAgentAppNames = DefaultUpbrainsAgentsConnection.find(
      (agent) => agent.agentName === '@upbrains/agent-ai-agent'
    ) as AgentConnection;
    const aiAgentCredRequest: UpsertSecretTextRequest = {
      projectId,
      agentName: aiAgentAppNames?.agentName,
      name: aiAgentAppNames?.name,
      type: AppConnectionType.SECRET_TEXT,
      value: {
        secret_text: String('') + '|' + String(password), //TO-DO JWT approach for open ai token
        type: AppConnectionType.SECRET_TEXT,
      },
    };
    this.connectionService.upsert(aiAgentCredRequest).toPromise();

    if (userConnections?.has_smart_classifier) {
      const smartClassifierAppNames = DefaultUpbrainsAgentsConnection.find(
        (agent) => agent.agentName === '@upbrains/agent-smart-classifier'
      ) as AgentConnection;
      const smartClassifierCredRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: smartClassifierAppNames?.agentName,
        name: smartClassifierAppNames?.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          secret_text: String(password),
          type: AppConnectionType.SECRET_TEXT,
        },
      };
      this.connectionService.upsert(smartClassifierCredRequest).toPromise();
    }
    if (userConnections?.has_conversation_skills) {
      const conversationAppNames = DefaultUpbrainsAgentsConnection.find(
        (agent) => agent.agentName === '@upbrains/agent-conversation-skills'
      ) as AgentConnection;
      const conversationCredRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: conversationAppNames?.agentName,
        name: conversationAppNames?.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          secret_text: String(password),
          type: AppConnectionType.SECRET_TEXT,
        },
      };
      this.connectionService.upsert(conversationCredRequest).toPromise();
    }

    if (userConnections?.has_document_skills) {
      const documentAppNames = DefaultUpbrainsAgentsConnection.find(
        (agent) => agent.agentName === '@upbrains/agent-document-skills'
      ) as AgentConnection;
      const documentCredRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: documentAppNames?.agentName,
        name: documentAppNames?.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          secret_text: String(password),
          type: AppConnectionType.SECRET_TEXT,
        },
      };
      this.connectionService.upsert(documentCredRequest).toPromise();
    }

    if (userConnections?.has_response_assist) {
      const responseAppNames = DefaultUpbrainsAgentsConnection.find(
        (agent) => agent.agentName === '@upbrains/agent-response-assist'
      ) as AgentConnection;
      const responseCredRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: responseAppNames?.agentName,
        name: responseAppNames?.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          secret_text: String(password),
          type: AppConnectionType.SECRET_TEXT,
        },
      };
      this.connectionService.upsert(responseCredRequest).toPromise();
    }

    if (userConnections?.has_save_in_database) {
      const responseAppNames = DefaultUpbrainsAgentsConnection.find(
        (agent) => agent.agentName === '@upbrains/agent-save-in-upbrains'
      ) as AgentConnection;
      const responseCredRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: responseAppNames?.agentName,
        name: responseAppNames?.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          secret_text: String(password),
          type: AppConnectionType.SECRET_TEXT,
        },
      };
      this.connectionService.upsert(responseCredRequest).toPromise();
    }

    if (userConnections.has_microsoft_cred) {
      const microsoftAppNames = UpbrainsServicesAgentConnections.find(
        (agent) => agent.agentName === '@upbrains/agent-microsoft-outlook'
      ) as AgentConnection;
      const microsoftCredRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: microsoftAppNames?.agentName,
        name: microsoftAppNames?.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          secret_text: String(password),
          type: AppConnectionType.SECRET_TEXT,
        },
      };
      this.connectionService.upsert(microsoftCredRequest).toPromise();
    }

    if (userConnections.has_one_drive_cred) {
      const microsoftOneDriveAppNames: AgentConnection =
        UpbrainsServicesAgentConnections.find(
          (agent) => agent.agentName === '@upbrains/agent-microsoft-onedrive'
        ) as AgentConnection;
      const microsoftOneDriveRequest: UpsertSecretTextRequest = {
        projectId,
        agentName: microsoftOneDriveAppNames.agentName,
        name: microsoftOneDriveAppNames.name,
        type: AppConnectionType.SECRET_TEXT,
        value: {
          type: AppConnectionType.SECRET_TEXT,
          secret_text: String(password),
        },
      };
      this.connectionService.upsert(microsoftOneDriveRequest).toPromise();
    }
  };

  private async signUpWithUpbrains(
    email: string,
    name: string,
    upbrainsToken: string,
    password: string
  ): Promise<AuthenticationResponse | null> {
    const [firstName, lastName = ' '] = name.split(' ');
    try {
      const response = await this.mainAuth
        .signUp({
          email,
          password,
          firstName,
          lastName,
          trackEvents: false,
          newsLetter: false,
          upbrainsToken,
          companyName: 'platform',
        })
        .toPromise();

      if (response?.body) {
        if (response?.body.permission) {
          this.permissionService.initialPermissions(response?.body.permission);
        }

        await this._saveUser(response.body);
        return response.body;
      }
      return null;
    } catch (error) {
      console.error('Error during sign-up', error);
      return null;
    }
  }

  private async resetPasswordWithUpbrains(
    email: string,
    newPassword: string,
    teamId: string
  ): Promise<AuthenticationResponse | null> {
    try {
      const resetData = await this.resetPassword({
        email,
        newPassword,
        teamId,
      });

      if (resetData) {
        await this._saveUser(resetData);

        return resetData;
      }
      return null;
    } catch (error) {
      console.error('Error during reset password', error);
      return null;
    }
  }

  private async signInWithUpbrains(
    email: string,
    password: string,
    teamId: string
  ): Promise<AuthenticationResponse | null> {
    try {
      const response = await this.mainAuth
        .signIn({ email, password, teamId })
        .toPromise();
      if (response?.body) {
        this.permissionService.initialPermissions(response?.body?.permission);
        await this._saveUser(response.body);
        return response.body;
      }
      return null;
    } catch (error) {
      console.error('Error during sign-in', error);
      return null;
    }
  }

  private async _saveUser(user: AuthenticationResponse): Promise<void> {
    this.mainAuth.saveToken(user.token);
    this.mainAuth.updateUser(user);
  }

  upbrainsLogout(): void {
    localStorage.removeItem('upbrainsUser');
    localStorage.removeItem(environment.jwtTokenName);
    localStorage.removeItem(environment.userPropertyNameInLocalStorage);
    localStorage.removeItem('isAdmin');
    // this.currentUserSubject.next(undefined);
    this.flagsService.reinitialiseFlags();
    window.location.href = `${environment.igniteUrl}/dashboard`;
  }
  private storeUpbrainsUser = (user: UpbrainsUser, token: string): void => {
    this.store.dispatch(saveUpbrainsUser({ upbrainsUser: { ...user, token } }));
  };
}
