import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import {
  AuthenticationService,
  RedirectService,
  sanitizeInput,
} from '@upbrains/ui/common';
import { FlagService } from '@upbrains/ui/common';
import {
  containsSpecialCharacter,
  containsUppercaseCharacter,
  containsLowercaseCharacter,
  containsNumber,
} from '@upbrains/ui/common';
import {
  ApEdition,
  ApFlagId,
  ErrorCode,
  SignUpRequest,
} from '@upbrains/shared';
import { OtpType } from '@upbrains/ee-shared';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';

export interface UserInfo {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
  trackEvents: FormControl<boolean>;
  newsLetter: FormControl<boolean>;
  companyName: FormControl<string>;
}
@Component({
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent implements OnInit {
  registrationForm: FormGroup<UserInfo>;
  readonly emailIsUsedErrorName = 'emailIsUsed';
  loading = false;
  tokenError = false;
  emailExists = false;
  emailChanged = false;
  emailValueChanged$: Observable<string>;
  signUp$: Observable<void> | undefined;
  signedUpEnabled$: Observable<boolean>;
  privacyPolicyUrl$: Observable<string>;
  termsOfServiceUrl$: Observable<string>;
  signUpDone = false;
  invitationOnlySignup = false;
  domainIsNotAllowed = false;
  showNewsLetterCheckbox$: Observable<boolean>;
  emailLoginsEnabled$: Observable<boolean>;
  validConfirmPassword = true;
  invitationToken?: string;
  companyName?: string;
  showNotFoundMemberOrProjectOwnerMessage = false;
  teamId: string | null = null;
  readonly OtpType = OtpType;
  constructor(
    private formBuilder: FormBuilder,
    public flagService: FlagService,
    public authenticationService: AuthenticationService,
    private redirectService: RedirectService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.emailLoginsEnabled$ = this.flagService.isFlagEnabled(
      ApFlagId.EMAIL_AUTH_ENABLED
    );
    this.privacyPolicyUrl$ = this.flagService.getStringFlag(
      ApFlagId.PRIVACY_POLICY_URL
    );
    this.termsOfServiceUrl$ = this.flagService.getStringFlag(
      ApFlagId.TERMS_OF_SERVICE_URL
    );
    this.showNewsLetterCheckbox$ = this.getShowNewsLetterCheckbox$();
    this.invitationToken =
      this.activeRoute.snapshot.queryParams['invitation_token'];
    this.companyName = this.activeRoute.snapshot.queryParams['company_name'];
    this.registrationForm = this.buildForm();
    this.signedUpEnabled$ = this.flagService.isSignedUpEnabled();
    // this.signedUpEnabled$ = of(false);
    this.emailValueChanged$ = this.getEmailInputListener$();
  }
  ngOnInit(): void {
    const email = this.activeRoute.snapshot.queryParamMap.get('email');
    this.teamId = this.activeRoute.snapshot.queryParamMap.get('team_id');

    if (email) {
      this.registrationForm.controls.email.setValue(email);
    }
  }

  signUp() {
    if (
      this.registrationForm.controls.confirmPassword.valid &&
      this.registrationForm.errors?.['passwordsMismatch']
    ) {
      this.registrationForm.controls.confirmPassword.setErrors({
        valid: false,
      });
    }

    if (this.registrationForm.valid && !this.loading) {
      this.loading = true;

      this.invitationOnlySignup = false;
      this.domainIsNotAllowed = false;

      const sanitizedFirstName = sanitizeInput(
        this.registrationForm.controls.firstName.value
      );
      const sanitizedLastName = sanitizeInput(
        this.registrationForm.controls.lastName.value
      );
      const sanitizedCompanyName = sanitizeInput(
        this.registrationForm.controls.companyName.value
      );
      const sanitizedEmail = sanitizeInput(
        this.registrationForm.controls.email.value
      );
      const sanitizedPassword = sanitizeInput(
        this.registrationForm.controls.password.value
      );

      if (
        this.registrationForm.controls.password.value !==
        this.registrationForm.controls.confirmPassword.value
      ) {
        this.validConfirmPassword = false;
        this.loading = false;
        return;
      }

      const referringUserId =
        this.activeRoute.snapshot.queryParamMap.get('referral') ?? undefined;

      const request: SignUpRequest = {
        ...this.registrationForm.getRawValue(),
        firstName: sanitizedFirstName,
        lastName: sanitizedLastName,
        email: sanitizedEmail,
        password: sanitizedPassword,
        referringUserId,
        invitationCode: this.invitationToken,
        companyName: sanitizedCompanyName,
        teamId: this.teamId ?? undefined,
      };

      this.signUp$ = this.authenticationService.signUp(request).pipe(
        tap((response) => {
          if (
            response &&
            response.body &&
            response.body.token &&
            response.body.verified
          ) {
            this.authenticationService.saveToken(response.body.token);
            this.authenticationService.saveUser(
              response.body,
              response.body.token
            );
          }
        }),
        tap((response) => {
          if (response && response.body?.verified) {
            this.redirect();
          } else {
            this.signUpDone = true;
          }
        }),
        catchError((err: HttpErrorResponse) => {
          const emailExists = err.status === HttpStatusCode.Conflict;
          if (emailExists) {
            this.registrationForm.controls.email.setErrors({
              ...this.registrationForm.controls.email.errors,
              [this.emailIsUsedErrorName]: true,
            });
          }

          this.showNotFoundMemberOrProjectOwnerMessage =
            err.status === StatusCodes.BAD_REQUEST &&
            err.error.code === ErrorCode.MEMBER_NOT_FOUND;

          this.invitationOnlySignup = err.status === HttpStatusCode.Forbidden;
          if (err.status === StatusCodes.FORBIDDEN) {
            this.invitationOnlySignup =
              err.error.code === ErrorCode.INVITATION_ONLY_SIGN_UP;
            this.domainIsNotAllowed =
              err.error.code === ErrorCode.DOMAIN_NOT_ALLOWED;
          }
          this.emailChanged = false;
          this.loading = false;
          return of(err);
        }),
        map(() => void 0)
      );
    }
  }

  getPasswordError(errorName: string) {
    return this.registrationForm.get('password')?.hasError(errorName);
  }

  getConfirmPasswordError(errorName: string) {
    return this.registrationForm.get('confirmPassword')?.hasError(errorName);
  }

  // Helper method to check if the form group has a passwords mismatch error
  hasPasswordMismatchError(): boolean | undefined {
    return (
      this.registrationForm.hasError('passwordsMismatch') &&
      this.registrationForm.get('confirmPassword')?.touched
    );
  }

  isPasswordInputIsFocused(passwordInputElement: HTMLInputElement) {
    return passwordInputElement == document.activeElement;
  }
  goBackToSignIn() {
    this.router.navigate(['/sign-in']);
  }
  redirect() {
    this.redirectService.redirect();
  }
  private getShowNewsLetterCheckbox$() {
    return this.flagService.getEdition().pipe(
      switchMap((ed) => {
        return this.flagService.getWebsiteName().pipe(
          map((name) => {
            switch (ed) {
              case ApEdition.CLOUD: {
                if (
                  typeof name === 'string' &&
                  name.toLowerCase() === 'activepieces'
                ) {
                  this.registrationForm.controls.newsLetter.setValue(false);
                }
                return false;
              }
              case ApEdition.COMMUNITY: {
                this.registrationForm.controls.newsLetter.setValue(false);
                return true;
              }
              case ApEdition.ENTERPRISE:
                return false;
            }
          })
        );
      })
    );
  }

  private buildForm() {
    return this.formBuilder.group(
      {
        firstName: new FormControl<string>('', {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(50)],
        }),
        lastName: new FormControl<string>('', {
          nonNullable: true,
          validators: [Validators.required, Validators.maxLength(50)],
        }),
        companyName: new FormControl<string>(
          {
            value:
              this.invitationToken && this.companyName ? this.companyName : '',
            disabled: Boolean(this.invitationToken),
          },
          {
            nonNullable: true,
            validators: [Validators.required, Validators.maxLength(50)],
          }
        ),
        email: new FormControl<string>('', {
          nonNullable: true,
          validators: [
            Validators.email,
            Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$'),
            Validators.required,
            Validators.maxLength(200),
          ],
        }),
        password: new FormControl<string>('', {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(64),
            containsSpecialCharacter(),
            containsUppercaseCharacter(),
            containsLowercaseCharacter(),
            containsNumber(),
          ],
        }),
        confirmPassword: new FormControl<string>('', {
          nonNullable: true,
          validators: [Validators.required],
        }),
        trackEvents: new FormControl<boolean>(true, { nonNullable: true }),
        newsLetter: new FormControl<boolean>(false, { nonNullable: true }),
      },
      { validators: this.passwordsMatchValidator }
    );
  }

  private passwordsMatchValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordsMismatch: true };
  };

  private getEmailInputListener$() {
    return this.registrationForm.controls.email.valueChanges.pipe(
      tap(() => {
        const errors = this.registrationForm.controls.email.errors;
        if (errors && errors[this.emailIsUsedErrorName]) {
          delete errors[this.emailIsUsedErrorName];
        }
        this.registrationForm.controls.email.setErrors(errors);
      })
    );
  }
}
