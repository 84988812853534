import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanData } from '@upbrains/shared';
import { NavigationService } from '@upbrains/ui/common';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})
export class SubscriptionPlansComponent implements OnInit {
  subscriptionPlansList: PlanData[] = [];
  monthYearStatus = 'year';
  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  title: string | null = '';

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.title = this.route.snapshot.queryParamMap.get('title');
    this.subscriptionPlansList = [
      {
        planName: 'Starter Plan',
        planPrice: 14.99,
        userSeats: 1,
        maxUserSeats: 20,
        totalPrice: 14.99,
        planFeatures: [
          {
            description: 'Free Copilot Credits (per seat / month): 1500',
            status: true,
          },
          { description: 'Self-Serve Onboarding', status: true },
          { description: 'Standard Support', status: true },
          { description: 'Automated AI agents with workflows', status: false },
        ],
        planPackages: [
          { title: 'Self Serve ($0)', value: 0, isSelected: true },
        ],
      },
      {
        planName: 'Professional Plan',
        currentPlan: true,
        planPrice: 29.99,
        userSeats: 1,
        automationCredits: 100000,
        totalPrice: 29.99,
        planFeatures: [
          {
            description: 'Free Copilot Credits (per seat / month): 3000',
            status: true,
          },
          { description: 'Self-Serve Onboarding', status: true },
          { description: 'Standard Support (Email)', status: true },
          {
            description: '10 Automated AI agents with workflows',
            status: true,
          },
        ],
        planPackages: [
          { title: 'Self Serve ($0)', value: 0, isSelected: false },
          {
            title: 'Standard Package ($799.99)',
            value: 799.99,
            isSelected: true,
          },
          {
            title: 'Premium Package ($2499.99)',
            value: 2499.99,
            isSelected: false,
          },
        ],
      },
      {
        planName: 'Premium Plan',
        planPrice: 49.99,
        userSeats: 1,
        maxUserSeats: 20,
        automationCredits: 100000,
        totalPrice: 49.99,
        planFeatures: [
          {
            description: 'Free Copilot Credits (per seat / month): 5000',
            status: true,
          },
          { description: 'Self-Serve Onboarding', status: true },
          { description: 'Premium Support (Email)', status: true },
          {
            description: '25 Automated AI agents with workflows',
            status: true,
          },
          { description: 'Continuous Learning', status: true },
        ],
        planPackages: [
          { title: 'Self Serve ($0)', value: 0, isSelected: false },
          {
            title: 'Premium Package ($2499.99)',
            value: 2499.99,
            isSelected: true,
          },
        ],
      },
      {
        planName: 'Enterprise',
        planFeatures: [
          { description: 'Custom Workflows', status: true },
          { description: 'Custom Trained AI Models', status: true },
          { description: 'Dedicated Onboarding and Training', status: true },
          { description: 'Premium Support', status: true },
        ],
      },
    ];
  }

  redirectToHome(newWindow: boolean) {
    this.navigationService.navigate('/forms', newWindow);
  }

  redirectToSubscriptions(newWindow: boolean) {
    this.navigationService.navigate('/subscriptions', newWindow);
  }

  handleMonthlyYearly() {
    if (this.monthYearStatus === 'month') {
      this.monthYearStatus = 'year';
    } else {
      this.monthYearStatus = 'month';
    }
  }
}
