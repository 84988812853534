import { Static, Type } from '@sinclair/typebox'

export const UserSubscription = Type.Object({
    additional_credits: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    billing_period: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    excess_unit_price: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    id: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    monthly_transaction_count: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    plan_name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    recurring_charge: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    service_feature: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    service_id: Type.Optional(Type.Union([Type.Number(), Type.Null()])),
    service_name: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    stripe_price_id: Type.Optional(Type.Union([Type.String(), Type.Null()])),
    stripe_subscription_id: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    subscription_exceeded_usage: Type.Optional(
        Type.Union([Type.Boolean(), Type.Null()]),
    ),
    subscription_quantity: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    subscription_renewal_date: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
    subscription_renewal_date_timestamp: Type.Optional(
        Type.Union([Type.Number(), Type.Null()]),
    ),
    subscription_status: Type.Optional(
        Type.Union([Type.String(), Type.Null()]),
    ),
})

export const SubscriptionCheck = Type.Object({
    has_subscription: Type.Boolean(),
    status: Type.Number(),
    team_id: Type.Number(),
    user_email: Type.String(),
    user_subscriptions: Type.Array(UserSubscription),
})

export const SubscriptionCheckResult = Type.Object({
    api_key: Type.String(),
    company_name: Type.String(),
    first_name: Type.String(),
    last_name: Type.String(),
    is_admin: Type.Boolean(),
    subscription_check: SubscriptionCheck,
})

export const SubscriptionCheckApi = Type.Object({
    results: SubscriptionCheckResult,
    status: Type.Number(),
})

export type UserSubscriptionType = Static<typeof UserSubscription>
export type SubscriptionCheckRequest = Static<typeof SubscriptionCheck>
export type SubscriptionCheckApiRequest = Static<typeof SubscriptionCheckApi>
