<div class="ap-relative">
  <!-- Header -->
  <div
    class="ap-w-full ap-sticky ap-top-0 ap-left-0 ap-flex ap-flex-row ap-justify-between ap-items-center ap-h-[80px] ap-z-50 ap-shadow-[0px_1px_0px_#f0f0f0] ap-bg-[#f5f5f5]"
  >
    <div
      class="ap-absolute ap-w-full ap-h-full ap-left-0 ap-top-0 ap-flex ap-items-center ap-justify-center ap-p-3 ap-pointer-events-none"
    >
      <div class="ap-h-[30px]">
        <img
          [src]="logoSrc || 'assets/img/custom/logo/full-logo.svg'"
          class="ap-max-w-full ap-h-full"
          alt="Upbrains AI"
        />
      </div>
    </div>

    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-start ap-items-center ap-gap-2"
    >
      <ap-icon-button
        [width]="20"
        [height]="20"
        iconUrl="assets/img/custom/dashboard/home.svg"
        tooltipText="Home"
        i18n-tooltipText
        (buttonClicked)="redirectToHome(false)"
        (auxclick)="redirectToHome(true)"
      ></ap-icon-button>
      <div class="ap-flex ap-flex-row ap-gap-2">
        <div
          class="mat-mdc-tooltip-trigger ap-typography-body-1 ap-truncate ap-max-w-[150px] ap-cursor-pointer hover:ap-text-[#000000] ng-tns-c2280036445-12 ng-star-inserted"
          (click)="redirectToSubscriptions(false)"
          (auxclick)="redirectToSubscriptions(true)"
        >
          Subscriptions
        </div>
        <div
          class="ap-typography-body-1 ap-text-placeholder ng-tns-c2280036445-12 ng-star-inserted"
        >
          /
        </div>
        <div
          class="mat-mdc-tooltip-trigger !ap-font-semibold ap-typography-body-1 viewed-text-container ng-star-inserted"
        >
          {{ title }}
        </div>
      </div>
    </div>
    <div
      class="ap-flex-1 ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2"
    ></div>
  </div>

  <!-- Body -->
  <div
    class="container-height ap-shadow-step-container-ds ap-bg-white ap-p-10 ap-overflow-auto"
  >
    <div
      class="ap-flex ap-flex-col ap-justify-between ap-items-center ap-font-inter ap-text-[#344054]"
    >
      <div class="ap-font-bold ap-text-5xl">Pick your Plan</div>
      <div class="ap-font-normal ap-text-xl ap-mt-11">
        Start automating your workflows with UpBrains AI
      </div>
      <ng-container *ngIf="monthYearStatus === 'year'">
        <div
          class="ap-bg-[#ECFDF3] ap-py-2 ap-px-6 ap-text-[#039855] ap-font-normal ap-rounded-3xl ap-mt-16"
        >
          Get <span class="ap-font-semibold">20% off</span> with a
          <span class="ap-font-semibold">yearly plan!</span>
        </div>
      </ng-container>
      <div
        class="ap-relative ap-flex ap-flex-row ap-justify-between ap-bg-[#F2F4F7] ap-p-[6px] ap-mt-7 ap-mb-14 ap-h-[56px] ap-w-[327px] ap-rounded-2xl ap-cursor-pointer monthly-yearly-container"
      >
        <div
          (click)="handleMonthlyYearly()"
          class="ap-w-[157px] ap-h-[44px] ap-bg-[#F2F4F7] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm"
        >
          Monthly
        </div>
        <div
          (click)="handleMonthlyYearly()"
          class="ap-w-[157px] ap-h-[44px] ap-bg-[#F2F4F7] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm"
        >
          Yearly
        </div>
        <div
          (click)="handleMonthlyYearly()"
          class="ap-w-[157px] ap-h-[44px] ap-bg-[#FFFFFF] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm ap-text-[#1570EF] ap-absolute ap-rounded-xl"
          [ngStyle]="{ left: monthYearStatus === 'month' ? '6px' : '163px' }"
        >
          {{ monthYearStatus === 'month' ? 'Monthly' : 'Yearly' }}
        </div>
      </div>
    </div>
    <div
      class="ap-flex ap-flex-wrap ap-justify-center ap-items-center ap-gap-4"
    >
      <ng-container *ngIf="subscriptionPlansList">
        <ng-container
          *ngFor="let subscriptionPlanList of subscriptionPlansList"
        >
          <ap-subscription-plan
            [planData]="subscriptionPlanList"
            [monthYearStatus]="monthYearStatus"
          />
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
