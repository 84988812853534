<div
  class="ap-bg-[#e0ebf6] ap-px-4 ap-py-2 ap-flex ap-flex-row ap-items-center ap-justify-between ap-gap-6 ap-rounded-lg ap-cursor-pointer"
  (click)="handleSubscription()"
>
  <div>
    <p>{{ planName }} Plan</p>
    <ng-container *ngIf="planName?.toLocaleLowerCase()?.includes('trial')">
      <p class="ap-text-xs ap-font-normal">{{ daysLeft }} days left</p>
    </ng-container>
  </div>
  <!-- <ap-button actionButton btnSize="medium" btnColor="primary" i18n>
    <div class="ap-flex ap-items-center loading-template">Upgrade</div>
  </ap-button> -->
</div>
