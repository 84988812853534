import { Component, Input, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { PlanData } from '@upbrains/shared';

@Component({
  selector: 'ap-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
  @Input() planData: PlanData;
  @Input() monthYearStatus: string;

  selectedPackageValue: number | undefined = 0;
  planButtonContent: string = '';
  totalPrice: number | undefined = 0;
  minUserSeats: number = 1;
  maxUserSeats: number = 1;

  ngOnInit(): void {
    this.planButtonContent = this.planData.planName
      .toLocaleLowerCase()
      .includes('starter')
      ? 'Get Started'
      : this.planData.planName.toLocaleLowerCase().includes('enterprise')
      ? 'Contact Sales'
      : 'Upgrade';

    this.selectedPackageValue = this.planData.planPackages?.find(
      (planPackage) => planPackage.isSelected
    )?.value;

    this.calculateTheTotalPrice();

    this.maxUserSeats = this.planData.maxUserSeats
      ? this.planData.maxUserSeats
      : 100;
  }

  onUserSeatsInputChange(event: any) {
    const value = event.target.value;

    if (value === '' || value < this.minUserSeats) {
      this.planData.userSeats = this.minUserSeats;
    } else if (value > this.maxUserSeats) {
      this.planData.userSeats = this.maxUserSeats;
    } else {
      this.planData.userSeats = value;
    }

    this.calculateTheTotalPrice();
  }

  onCreditsInputChange(event: any) {
    const value = event.target.value;
    if (value === '' || value < 0) {
      this.planData.automationCredits = 0;
    } else if (value > 500000) {
      this.planData.automationCredits = 500000;
    } else {
      this.planData.automationCredits = value;
    }
    this.calculateTheTotalPrice();
  }

  calculateTheTotalPrice() {
    if (this.planData.userSeats && this.planData.planPrice) {
      this.totalPrice = this.planData.userSeats * this.planData.planPrice;

      if (this.planData.automationCredits) {
        this.totalPrice =
          (this.planData.automationCredits * 10) / 1000 +
          this.planData.userSeats * this.planData.planPrice;
      }
    }

    if (this.totalPrice && this.selectedPackageValue) {
      this.totalPrice = this.totalPrice + this.selectedPackageValue;
    }
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }

  onSelectionChange(event: MatRadioChange) {
    this.selectedPackageValue = event.value;
    this.calculateTheTotalPrice();
  }

  handleUpgrade(planName: string) {
    console.log('planName', planName);
  }
}
