import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SubscriptionPlansComponent } from './pages/subscription-plans/subscription-plans.component';
import { UiCommonModule } from '@upbrains/ui/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SubscriptionLayoutRouting } from './subscriptions.routing';

const exportedComponents = [SubscriptionPlansComponent];

@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
    RouterModule.forChild(SubscriptionLayoutRouting),
    FormsModule, // Add FormsModule here
    ReactiveFormsModule, // Add ReactiveFormsModule if needed
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AsyncPipe,
  ],

  declarations: [...exportedComponents],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [...exportedComponents],
})
export class FeatureSubscriptionsModule {}
